body {
  min-height: 100vh;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 13px !important;
  color: #222;
  overflow-x: hidden;
}

body .root {
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background: #FFFFFF;
}

.btn {
  font-size: 13px !important;
}

input.form-control, textarea.form-control{
  font-size: 13px !important;
  border-color: #c2c2c2;
}

a, a:hover {
  color: #0b62e5;
}

hr {
  background-color: #c2c2c2;
}

footer.login-footer {
  position: sticky;
  top: 100%;
}
footer {
  height: 50px;
  background-color: #2A4A9D;
  color: #ffffff;
}
.content-footer{
  height: 50px;
  width: 300px;
  flex-shrink: 0;
}

.logo-wrap {
  padding: 20px;
}

.logo {
  width: 253px;
  height: 40px;
}

.logo img{
  width: 253px;
}

.login-header .logo {
  width: 350px;
  height: 35px;
}

.login-header .logo img{
  width: 350px;
}

.line {
  border-bottom: 1px #c2c2c2 solid ;
}

.login-header {
  height: 120px;
  width: 920px;
}
.login-main-content {
  margin-bottom: 80px;
}

.login-title {
  font-weight: 700;
  font-size: 30px;
  margin: 30px 0 10px 0;
}
.login-title img {
  width: 32px;
  height: 22px;
  margin: 0 20px 0 0;
}

.login-content-box {
  height: 250px;
  background: #F8FFFF;
  border: 3px solid #2A4A9D;
  border-radius: 10px;
  margin: 45px 0 60px 0;
  width: 850px;
}

.login-content {
  width: 450px;
}

.login-description {
  width: 920px;
  font-size: 12px;
}
.login-description .title {
  font-size: 17px;
}

.menu {
  width: 300px;
  background-color: #2A4A9D;
  place-self: stretch;
  height: 100vh;
  position: fixed;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.flex-glow {
  flex-grow: 1;
}

.content {
  margin-left:300px;
  padding-top: 64px;
}

.content-header {
  position: fixed;
  z-index: 1;
  border-bottom: 2px solid #c2c2c2;
}


.content-body {
  margin-top :64px;
}

.main-menu-content a {
  font-size: 14px;
  height: 60px;
  color: #ffffff;
  padding-left: 35px;
}

.main-menu-content.active-menu1 a.menu1, 
.main-menu-content.active-menu2 a.menu2, 
.main-menu-content.active-menu3 a.menu3, 
.main-menu-content.active-menu4 a.menu4, 
.main-menu-content.active-menu5 a.menu5, 
.main-menu-content.active-menu6 a.menu6, 
.main-menu-content.active-menu7 a.menu7, 
.main-menu-content.active-menu8 a.menu8, 
.main-menu-content.active-menu9 a.menu9 {
  color: #2A4A9D;
  background-color: #ffffff;
}

.main-menu-content.auth-normal a.menu4,
.main-menu-content.auth-normal a.menu6,
.main-menu-content.auth-normal a.menu7,
.main-menu-content.auth-normal a.menu8 {
  display: none !important;
}
.main-menu-content a:hover {
  color: #2A4A9D;
  background-color: #ffffff;
}

.main-menu-content .act,
.main-menu-content .deact {
  margin-right: 1rem;
}

.main-menu-content a .deact {
  display:block;
}
.main-menu-content a .act {
  display:none;
}
.main-menu-content a:hover .deact {
  display:none;
}
.main-menu-content a:hover .act {
  display:block;
}
.main-menu-content.active-menu1 a.menu1 .act, 
.main-menu-content.active-menu2 a.menu2 .act, 
.main-menu-content.active-menu3 a.menu3 .act, 
.main-menu-content.active-menu4 a.menu4 .act, 
.main-menu-content.active-menu5 a.menu5 .act, 
.main-menu-content.active-menu6 a.menu6 .act, 
.main-menu-content.active-menu7 a.menu7 .act, 
.main-menu-content.active-menu8 a.menu8 .act, 
.main-menu-content.active-menu9 a.menu9 .act {
  display:block;
}
.main-menu-content.active-menu1 a.menu1 .deact, 
.main-menu-content.active-menu2 a.menu2 .deact, 
.main-menu-content.active-menu3 a.menu3 .deact, 
.main-menu-content.active-menu4 a.menu4 .deact, 
.main-menu-content.active-menu5 a.menu5 .deact, 
.main-menu-content.active-menu6 a.menu6 .deact, 
.main-menu-content.active-menu7 a.menu7 .deact, 
.main-menu-content.active-menu8 a.menu8 .deact, 
.main-menu-content.active-menu9 a.menu9 .deact {
  display:none;
}

header.main-menu-header {
  height: 64px;
  background-color: #e5e5e5;
  padding-left: 300px;
  width: 100vw;
}
.main-menu-header .company {
  font-size: 16px;
}
.main-menu-header .authority {
  color: #ffffff;
  background-color: #f0ad4e;
  border-radius: 20px;
  padding : 0.2rem 0.5rem 0.2rem 0.5rem;
  font-size: 13px;
}
.main-menu-header.auth-normal .authority {
  display: none !important;
}

a {
  text-decoration: none !important;
}

main {
  padding: 1.5rem;
}

.required {
  color: #ff0000;
}

.error-message {
  color: #ff0000;
  white-space: pre;
  margin: 0.5rem 0 0.5rem 0;
}

.dialog {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 1;
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #2A4A9D;
  opacity: 0.1;
}

.modal-body p {
  white-space: pre-wrap;
}

.btn-accept {
  width: 140px;
  height: 38px;
  background-color: #0275d8;
  color: #ffffff;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-accept:hover {
  background-color: #0255b8;
  color: #ffffff;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-back {
  width: 110px;
  height: 38px;
  background-color: #e6e6e6;
  color: #222222;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-back:hover {
  background-color: #c6c6c6;
  color: #222222;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-search {
  width: 110px;
  height: 38px;
  background-color: #e6e6e6;
  color: #222222;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-search:hover {
  font-weight: bold;
  background-color: #c6c6c6;
  color: #222222;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-delete {
  width: 140px;
  height: 38px;
  background-color: #d9534f;
  color: #ffffff;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-delete:hover {
  background-color: #b9332f;
  color: #ffffff;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-dialog-yes, .btn-dialog-ok {
  width: 70px;
  height: 38px;
  background-color: #0275d8;
  color: #ffffff;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-dialog-yes:hover, .btn-dialog-ok:hover {
  background-color: #0255b8;
  color: #ffffff;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-dialog-no {
  width: 70px;
  height: 38px;
  background-color: #e6e6e6;
  color: #222222;
  border: 1px solid #adadad;
  font-weight: 900;
}

.btn-dialog-no:hover {
  background-color: #c6c6c6;
  color: #222222;
  border: 1px solid #adadad;
  font-weight: 900;
}

input.form-control[type='text']::placeholder {
  color: #eee;
}

.radius-table {
  border:#c2c2c2 solid 1px;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
}

.radius-table th, .radius-table td {
  height: 35px;
  vertical-align: middle;
}

.radius-table th {
  border-style: none none none none;
}
.radius-table td {
  border-bottom-width: 0 !important;
  border-top: #c2c2c2 solid 1px !important;
}

.radius-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
.radius-table tbody tr:last-child td:last-child  {
  border-bottom-right-radius: 5px;
  margin-right: 8px;
}
.radius-table tbody tr:last-child td:first-child  {
  border-bottom-left-radius: 5px;
  margin-left: 8px;
}
.radius-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.dropdown label ,
.dropdown button {
  min-width: 150px;
  text-align: left;
}

.dropdown .dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
}

.password-change fieldset {
  margin-top: 0.5rem;
}

.password-change p {
  margin-bottom: 2rem;
}

.password-change input {
  width: 300px;
}

.company-list fieldset .form-control[type='text'] {
  width : 300px;
}
.company-register fieldset .form-control[type='text'], 
.company-register fieldset .form-control[type='password'] {
    width : 300px;
}
.company-register fieldset textarea {
    width : 500px;
    height: 90px;
}

.company-register fieldset p {
  margin-bottom: 1.5rem;
}


.flight-history-download .dropdown-gateway button,
.flight-history-download .dropdown-gateway .dropdown-menu {
  min-width: 300px;
}
.flight-history-download .dropdown-gateway .form-check-label {
  width: 100%;
}

.flight-history-download .form-control.date {
  width: 150px;
}

.flight-history-download .btn.dropdown-toggle.time label,
.flight-history-download .btn.dropdown-toggle.time button{
  width: 70px;
  min-width: none;
  text-align: left;
}
.flight-history-download .time.dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
}


.gateway-list.auth-normal .gateway_link,
.gateway-list.auth-admin .gateway_label,
.gateway-list.auth-normal .regist-button {
  display: none;
}


.gateway-register fieldset .form-control[type='text'], 
.gateway-register fieldset .form-control[type='password'] {
    width : 300px;
}
.gateway-register fieldset textarea {
    width : 500px;
    height: 90px;
}

.gateway-register fieldset p {
  margin-bottom: 1.5rem;
}

.drone-register fieldset .form-control[type='text'], 
.drone-register fieldset .form-control[type='password'] {
    width : 300px;
}
.drone-register fieldset textarea {
    width : 500px;
    height: 90px;
}

.drone-register fieldset p {
  margin-bottom: 1.5rem;
}

.logout {
  min-height: 1024px;
}

button img {
  margin-top: 1px;
  margin-right:0.5rem;
  vertical-align: text-top;
}

.spinner {
  min-height: 1024px;
  opacity: 0.1;
}
